<template>
  <v-app>
    <v-app-bar app>
      <v-btn v-if="loggedIn" style="visibility: hidden">
        {{ cornerButtonText }}
      </v-btn>
      <v-spacer></v-spacer>
      <v-toolbar-title>
        <h2>{{ title }}</h2>
      </v-toolbar-title>
      <v-spacer> </v-spacer>
      <v-btn color="secondary" v-if="loggedIn" @click="handleCornerButtonClick">
        {{ cornerButtonText }}
      </v-btn>
    </v-app-bar>

    <!-- Sizes your content based upon application components -->
    <v-main>
      <!-- Provides the application the proper gutter -->
      <v-container fluid>
        <!-- If using vue-router -->
        <router-view></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
import routes from './router/routes';

export default {
  data: () => ({
    title: "Links 🔗",
    cornerButtonText: "Leave",
  }),
  computed: {
    loggedIn() {
      return this.$route.name != "Home";
    },
  },
  methods: {
    handleCornerButtonClick() {
      this.$router.push(routes.HOME.path);
    }
  },
  beforeCreate: function () {
    document.documentElement.className = "remove-scrollbar";
  },
};
</script>
<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Ubuntu+Mono:wght@400;700&display=swap");
.remove-scrollbar {
  overflow-y: auto;
}
</style>
