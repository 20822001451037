import Home from "../views/Home.vue";

const routes = Object.freeze({
  HOME: {
    path: "/",
    name: "Home",
    component: Home,
  },
  GAME: {
    path: "/play",
    name: "Game",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Game.vue"),
  },
});

export default routes;
