<template>
  <div class="wrapper" @click="clicked">
    <div class="header-box">
        <h2>
            <slot></slot>
        </h2>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    clicked() {
      this.$emit("onclick");
    },
  },
};
</script>

<style scoped>
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.header-box {
  padding: 18px 30px;
  width: auto;
  background: #282828;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  font-family: Ubuntu Mono, sans-serif;
  font-style: normal;
  font-weight: bold;
  line-height: 40px;
  text-align: center;
  color: #ffffff;
  user-select: none;
}
</style>