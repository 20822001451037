<template>
  <v-form v-model="valid">
    <v-col
      align-self="center"
      class="d-flex flex-column justify-center align-center"
    >
      <header-box @onclick="handleClick(0)">Choose a Name</header-box>
      <v-text-field
        class="ma-6"
        placeholder="Name"
        outlined
        v-model="name"
        :rules="nameRules"
        :counter="10"
        required
      ></v-text-field>
      <header-box>Enter the Code</header-box>
      <v-text-field
        class="ma-6"
        placeholder="Code"
        outlined
        v-model="code"
        :rules="codeRules"
        :counter="4"
        required
      ></v-text-field>
      <v-btn color="primary" @click="submit"> PLAY </v-btn>
    </v-col>
  </v-form>
</template>


<script>
import HeaderBox from "../components/HeaderBox.vue";
import routes from "../router/routes";
import mutationTypes from "../store/mutation-types";
export default {
  name: "Home",
  components: {
    HeaderBox,
  },
  created() {
    this.name = this.$store.state[mutationTypes.NAME] ?? "";
    this.code = this.$store.state[mutationTypes.CODE] ?? "";
  },
  data: () => ({
    valid: false,
    name: "",
    code: "",
    nameRules: [
      (v) => !!v || "Name is required",
      (v) => v.length <= 10 || "Name must be less than 10 characters",
    ],
    codeRules: [
      (v) => !!v || "Code is required",
      (v) => v.length == 4 || "Code must be 4 characters long",
    ],
  }),
  methods: {
    submit: function () {
      this.$store.commit(mutationTypes.NAME, this.name);
      this.$store.commit(mutationTypes.CODE, this.code);
      this.$router.push(routes.GAME.path);
    },
  },
  watch: {
    code: function (newValue) {
      this.code = newValue?.toUpperCase();
    },
  },
};
</script>

<style scoped>
</style>
