import Vue from "vue";
import Vuex from "vuex";
import mutationTypes from "./mutation-types";
Vue.use(Vuex);

const SAVE_PREFIX = "LINKS_";
const SAVED_TYPES = [mutationTypes.NAME, mutationTypes.CODE];

/**
 * save to the local storage for persistent settings
 */
let saveLastId = 0;
function save(state) {
  clearTimeout(saveLastId);
  saveLastId = setTimeout(() => {
    SAVED_TYPES.forEach((type) =>
      localStorage.setItem(SAVE_PREFIX + type, state[type])
    );
  }, 10);
}
function load() {
  const data = SAVED_TYPES.reduce((d, type) => {
    d[type] = localStorage.getItem(SAVE_PREFIX + type);
    return d;
  }, {});
  console.log(data);
  return data;
}

export default new Vuex.Store({
  state: {
    [mutationTypes.NAME]: "",
    [mutationTypes.CODE]: "",
    ...load()
  },
  mutations: {
    [mutationTypes.NAME](state, payload) {
      state[mutationTypes.NAME] = payload;
      save(state);
    },
    [mutationTypes.CODE](state, payload) {
      state[mutationTypes.CODE] = payload;
      save(state);
    },
  },
  actions: {},
  modules: {},
});
